// =========================================================
// * Vue Material Kit PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import "bootstrap";

import axios from 'axios';
import VueAxios from 'vue-axios';

import i18n from "@/core/services/i18n.service.js";

import MaterialKit from "./plugins/material-kit";

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'popper.js/dist/popper.min.js'

import { VueBotUI } from 'vue-bot-ui'

import VueEllipseProgress from 'vue-ellipse-progress';

import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import mapDataRo from "@highcharts/map-collection/countries/ro/ro-all.geo.json";
import mapDataEuro from "@highcharts/map-collection/custom/europe.geo.json";
import markerClusters from "highcharts/modules/marker-clusters";

import proj4 from 'proj4';

// NOTE: when dealing with server side rendering as we are, check for window before doing things with it.
// If you're not doing server side rendering, then you don't need this check and can just assign straight to window.
if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

Vue.config.productionTip = false;

Vue.use(MaterialKit);
Vue.use(VueAxios, axios);
Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueEllipseProgress);
Vue.use(VueBotUI);
Vue.use(HighchartsVue);

markerClusters(Highcharts);
mapInit(Highcharts);

Highcharts.maps["roMap"] = mapDataRo;
Highcharts.maps["euMap"] = mapDataEuro;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
