<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter header-medium" parallax-active="true" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Cartografia Literaturii Române Vechi
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div>
          <div class="md-layout">
            <div class="md-layout-item">
              <template>
                <!-- <div class="md-layout-item mx-auto text-center">
              <div class="d-flex justify-content-end mb-3"> -->
                <div class="md-layout-item mx-auto text-center">
                <!-- <div class="d-flex justify-content-end">
                  <button v-if="!detailsView" type="button" class="btn btn-link" @click="onClickDetailsView()">Vizualizare detaliată</button>
                  <button v-else type="button" class="btn btn-link" @click="onClickBasicView()">Vizualizare de bază</button>
                </div> -->
              </div>
                <div class="row d-flex" v-if="!showProgress">
                  <!-- ro map judete -->
                  <!-- <div v-if="!detailsView">
                    <div class="row d-flex justify-content-center">
                      <roMap :mapData="mapData" @countyClicked="onCountyClicked" :initializeHashMapData="roCountiesData"></roMap>
                    </div>
                    <div class="m-5 mx-auto text-center">
                      <button type="button" class="btn btn-primary me-2" @click="onClickLocationsOutsideRo()">{{this.outsideRoText}}</button>
                      <button type="button" class="btn btn-primary" @click="onClickLocationsVague()">{{ this.vagueText }}</button>
                    </div>
                    <div class="row px-5 d-flex">
                      <h4>{{ selectedItem }}</h4>
                      <div class="row" v-for="item in mapDataFiltered" :key="item.id">
                        <div class="h6 mt-4">{{ item.title }}</div>
                        <div class="row">
                          <div class="display-inline">
                            <img :src="imgClock" class="size-small me-2" alt=""><span>{{ item.period }}</span>
                          </div>
                          <div v-if="item.place || item.placeNow" class="display-inline">
                            <img :src="imgLocation" class="size-small me-2" alt="">
                            <span v-if="item.place" class="me-2">{{ item.place }}</span>
                            <img v-if="item.placeNow" :src="imgArrowRight" class="size-small me-2"  alt="">
                            <span v-if="item.placeNow">{{ item.placeNow }}</span>
                          </div>
                        </div>
                        <div class="display-inline">
                          <img v-if="item.about || item.details" :src="imgDetails" class="size-small me-2" alt="">
                          <span v-if="item.about" class="me-1">{{ item.about }}</span>
                          <span v-if="item.details">{{ item.details }}</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- ro map plimbari -->
                  <div>
                    <mostCommonMap :mapData="mostCommonMapData" :initializeHashMapData="roCountiesData"></mostCommonMap>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="middle">
                    <vue-ellipse-progress loading :size="50" :progress="100" color="green" emptyColor="#a4acb3" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
// import roMap from "./RoMap";
import mostCommonMap from "./MostCommonMap";

export default {
  components: {
    // roMap, 
    mostCommonMap
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      roCountiesData: [],
      detailsView: false,
      showProgress: false,
      mapData: [],
      mapDataOutsideRo: [],
      mapDataVague: [],
      mostCommonMapData: {},
      selectedItem: '',
      outsideRoText: "Manuscrise în afara României",
      vagueText: "Manuscrise nelocalizate",
      mapDataFiltered: [],
      imgArrowRight: require("@/assets/img/svg/map/arrow-right-solid.svg"),
      imgLocation: require("@/assets/img/svg/map/location-dot-solid.svg"),
      imgClock: require("@/assets/img/svg/map/clock-solid.svg"),
      imgDetails: require("@/assets/img/svg/map/circle-info-solid.svg"),
    };
  },
  methods: {
    // onClickDetailsView() {
    //   this.detailsView = true;
    // },
    // onClickBasicView() {
    //   this.detailsView = false;
    // },
    getMostCommonMapData() {
      this.showProgress = true;
      this.mostCommonMapData = {};
      this.axios.get(`${process.env.VUE_APP_SERVER_URL}/map-most-common`).then(
        response => {
          if (response && response.status == 200) {
            this.mostCommonMapData = response.data;
            this.showProgress = false;
          }
        }
      );
    },
    // getMapData() {
    //   this.showProgress = true;
    //   this.mapData = [];
    //   this.axios.get(`${process.env.VUE_APP_SERVER_URL}/map`).then(
    //     response => {
    //       if (response && response.status == 200) {
    //         this.mapData = response.data;
    //         this.showProgress = false;
    //         this.computeLocationsVague();
    //         this.computeLocationsOutsideRo();
    //       }
    //     }
    //   );
    // },
    // onClickLocationsOutsideRo() {
    //   this.selectedItem = this.outsideRoText;
    //   this.mapDataFiltered = this.mapDataOutsideRo;
    // },
    // onClickLocationsVague() {
    //   this.selectedItem = this.vagueText;
    //   this.mapDataFiltered = this.mapDataVague;
    // },
    // computeLocationsOutsideRo() {
    //   this.mapDataOutsideRo = [];
    //   for (let item of this.mapData) {
    //     if (!item.countyAbbr && item.lat && item.lon) {
    //       this.mapDataOutsideRo.push(item);
    //     }
    //   }
    // },
    // computeLocationsVague() {
    //   this.mapDataVague = [];
    //   for (let item of this.mapData) {
    //     if (!item.countyAbbr && !item.lat && !item.lon) {
    //       this.mapDataVague.push(item);
    //     }
    //   }
    // },
    // onCountyClicked(places) {
    //   this.selectedItem = places.join(", ");
    //   this.mapDataFiltered = [];
    //   for (let item of this.mapData) {
    //     if (places.includes(item.place)) {
    //       this.mapDataFiltered.push(item);
    //     }
    //   }
    // },
    // displayPeriod(start, end) {
    //   if (start && end) {
    //     if (start !== end) {
    //       return `(${start} - ${end})`;
    //     } else if (start) {
    //       return `(${start})`;
    //     } else if (end) {
    //       return `(${end})`;
    //     }
    //   }
    //   return ""
    // },
    initializeHashMapData() {
      const COLOR_MARAMURES = "#2a9d8f";
      const COLOR_CRISANA = "#8ab17d";
      const COLOR_MUNTENIA = "#babb74";
      const COLOR_MOLDOVA = "#EFB366";
      const COLOR_TRANSILVANIA = "#f4a261";
      const COLOR_BANAT = "#E97C61";
      const COLOR_OLTENIA = "#EE8959";
      const COLOR_BUCOVINA = "#287271";
      const COLOR_DOBROGEA = "#264653";

      return [
        { 'hc-key': 'ro-bi', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-cs', value: 0, color: COLOR_BANAT },
        { 'hc-key': 'ro-tm', value: 0, color: COLOR_BANAT },
        { 'hc-key': 'ro-bt', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-bn', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-cj', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-ab', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-hd', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-mm', value: 0, color: COLOR_MARAMURES },
        { 'hc-key': 'ro-ms', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-sj', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-sm', value: 0, color: COLOR_MARAMURES },
        { 'hc-key': 'ro-ag', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-sb', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-vl', value: 0, color: COLOR_OLTENIA },
        { 'hc-key': 'ro-bv', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-cv', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-hr', value: 0, color: COLOR_TRANSILVANIA },
        { 'hc-key': 'ro-is', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-nt', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-ph', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-sv', value: 0, color: COLOR_BUCOVINA },
        { 'hc-key': 'ro-bc', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-br', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-bz', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-gl', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-vs', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-vn', value: 0, color: COLOR_MOLDOVA },
        { 'hc-key': 'ro-if', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-tl', value: 0, color: COLOR_DOBROGEA },
        { 'hc-key': 'ro-dj', value: 0, color: COLOR_OLTENIA },
        { 'hc-key': 'ro-gj', value: 0, color: COLOR_OLTENIA },
        { 'hc-key': 'ro-mh', value: 0, color: COLOR_OLTENIA },
        { 'hc-key': 'ro-ot', value: 0, color: COLOR_OLTENIA },
        { 'hc-key': 'ro-tr', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-cl', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-db', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-gr', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-il', value: 0, color: COLOR_MUNTENIA },
        { 'hc-key': 'ro-ct', value: 0, color: COLOR_DOBROGEA },
        { 'hc-key': 'ro-ar', value: 0, color: COLOR_CRISANA },
        { 'hc-key': 'ro-bh', value: 0, color: COLOR_CRISANA }
      ]
    },

  },
  async mounted() {
    this.roCountiesData = this.initializeHashMapData();
    // this.getMapData();
    this.getMostCommonMapData();

  },
};
</script>

<style lang="scss" scoped>
.size-small {
  height: 16px;
}
.cursor-pointer {
  cursor: pointer;
}

.pdf-entry {
  font-size: 12px;
}

.full-height {
  height: 100vh;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #287271 !important;
}
</style>
